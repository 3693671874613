import React, { useState } from "react";
import { AppBar, Toolbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Box } from "@mui/material";
import { Link } from "react-router-dom";

export function MenuBar() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar>
      <Toolbar>
        <Link to="/" underline="none" rel="noopener noreferrer">
          <Box
            component="img"
            sx={{
              height: { xs: 50, sm: 60, md: 80 },
              // Add any other styling you want
            }}
            alt="Phrog Logo"
            src="/images/PHROG.png"
          />
        </Link>
        <Box sx={{ flexGrow: 1 }} />{" "}
        {/* This will push the IconButton to the right */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        {/* <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Menu
        </Typography> */}
        {/* <Button variant="Text" href="/">
          Home
        </Button> */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem>
            <Button variant="Text" href="/lyrics?song=content">
              Farmer's Content
            </Button>
          </MenuItem>
          <MenuItem>
            <Button variant="Text" href="/lyrics?song=goodbye">
              Say Goodbye
            </Button>
          </MenuItem>
          <MenuItem>
            <Button variant="Text" href="/ErrorPage">
              Error 404
            </Button>
          </MenuItem>
          <MenuItem>
            <Button variant="Text" href="/QRCode">
              QR Code
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
