import React, { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { Lyrics } from "./Lyrics";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { MenuBar } from "./MenuBar"; // Import MenuBar component
import ErrorPage from "./ErrorPage";
import QRCode from "./QRCode";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

const MyTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(100, 100, 100)",
    },
    background: {
      default: "rgb(242, 242, 242)",
    },
    text: {
      primary: "rgb(28, 28, 30)",
    },
    divider: "rgb(199, 199, 204)",
    notification: "rgb(255, 69, 58)",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(10, 10, 10, 0.3)",
          //backdropFilter: "blur(10px)", // Optional: Adds a blur effect to the background
          //position: "sticky",
          top: 0,
          // zIndex: 1200, // Ensure it's above other elements, adjust as needed
          boxShadow: "none",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={MyTheme}>
      <MenuBar />
      <Box>
        <Routes>
          <Route path="/lyrics" element={<Lyrics />} />
          <Route path="/" element={<Home />} />
          <Route path="/QRCode" element={<QRCode />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

function Home() {
  useEffect(() => {
    document.title = "Home - Phrog Music";
  }, []);
  return (
    <>
      <Grid
        container
        direction="column"
        gap={4}
        paddingTop={{ xs: 10, sm: 5 }}
        height={{ xs: "800px", sm: "800px", md: "1000px" }}
        sx={{
          // height: "100vh", // Full viewport height
          // minHeight: // Minimum height of full viewport
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(/images/PhrogBushes.jpg)", // Path relative to the public directory
          backgroundSize: "cover", // Ensure the image covers the container
          backgroundPosition: "center", // Center the image
          backgroundRepeat: "no-repeat", // Prevent image repetition
          position: "relative", // Position relative for layering
        }}
      ></Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh", // Adjust as necessary
          textAlign: "center",
        }}
      >
        <Box
          paddingTop={{ xs: 5, md: 10 }}
          sx={{
            maxWidth: 1000,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <iframe
            height="100%"
            width="100%"
            src="https://www.youtube.com/embed/5SfXnKEMbQ8?si=jLBpzRCqrOx6-0Zz"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
    </>
  );
}

export default App;

function Footer() {
  const [value, setValue] = React.useState(0);

  return (
    <Box paddingTop={{ xs: 4, sm: 6, md: 8 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="TikTok"
          icon={<YouTubeIcon />}
          component={Link}
          to="https://www.instagram.com/phrogmusic2/"
          target="_blank"
        />
        <BottomNavigationAction
          label="Instagram"
          icon={<MusicNoteIcon />}
          component={Link}
          to="https://www.youtube.com/@phrogmusic2"
          target="_blank"
        />
        <BottomNavigationAction
          label="Youtube"
          icon={<InstagramIcon />}
          component={Link}
          to="https://www.tiktok.com/@phrogmusic2"
          target="_blank"
        />
      </BottomNavigation>
    </Box>
  );
}
