import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";

export function Lyrics() {
  const [searchParams] = useSearchParams();
  const song = searchParams.get("song");

  useEffect(() => {
    if (song === "content") {
      document.title = "Farmers Content - Phrog Music";
    } else if (song === "goodbye") {
      document.title = "Say Goodbye - Phrog Music";
    }
  }, [song]);

  return (
    <Grid
      container
      direction="column"
      gap={4}
      paddingTop={{ xs: 10, sm: 5 }}
      //   height={{ xs: "800px", sm: "800px", md: "1000px" }}
      sx={
        {
          // backgroundImage:
          //   "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(/images/PhrogSky.jpg)",
          // backgroundSize: "cover", // Ensure the image covers the container
          // backgroundPosition: "center", // Center the image
          // backgroundRepeat: "no-repeat", // Prevent image repetition
          // backgroundAttachment: "fixed", // Make the background image fixed
          // position: "relative", // Position relative for layering
        }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   height: "85vh", // Full viewport height, adjust as needed
          textAlign: "center", // Center text within the Typography component
          marginTop: { xs: 4, md: 15 },
        }}
      >
        <Box
        //   sx={{
        //     // maxHeight: "60vh", // Adjust based on your preference
        //     overflowY: "scroll", // Enable vertical scrolling
        //     paddingY: 2, // Optional: Add some padding
        //     "&::-webkit-scrollbar": {
        //       display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari)
        //     },
        //     "-ms-overflow-style": "none", // Hide the scrollbar for Internet Explorer and Edge
        //     "scrollbar-width": "none", // Hide the scrollbar for Firefox
        //   }}
        >
          {/* <canvas id="c"> */}
          <Typography variant="p">
            {song === "content" ? (
              <>
                <Typography variant="h3">Farmer's Content</Typography>
                <br />
                You're probably wondering what I’ve been doing
                <br />I lost track of time now my life’s ruined
                <br />
                Because the scallywag raccoon ate my melon
                <br />
                That trash panda got me yelling
                <br />
                <br />
                Finally said I was going to Johann
                <br />
                Told me I needed the way back to go on
                <br />
                Now I have the chance to fulfill my destiny
                <br />
                So I can become a better me
                <br />
                <br />I thought I was crazy baby
                <br />
                Till my last breath caught me lately
                <br />I travelled far to the end of time
                <br />
                And I knew that you would be mine
                <br />
                My energy started dropping down down down
                <br />
                <br />
                After the visits with Johann
                <br />
                I found the way back to go on
                <br />
                Had the chance to fulfill my destiny
                <br />
                But I moved to a different society
                <br />
                <br />
                So I was lost in this land
                <br />
                Till I decided to take a stand
                <br />
                In my soul I tore myself apart
                <br />I gagged all day, so I clicked restart
                <br />
                <br />
                The fire on my feet was never meant to be
                <br />
                You paralyzed me from the lie
                <br />
                That told me that I would fly
                <br />
                The journey that I was put on
                <br />
                Could have never gone so wrong
                <br />
                Now it’s time for me to go on
                <br />
                <br />
                Too many zeros in the time machine
                <br />
                In the Cretaceous, just to set the scene
                <br />I thought I could run a whole lot faster
                <br />
                ‘Till I was chased by a velociraptor
                <br />I started tending to some crops
                <br />I also rode a triceratops
                <br />I sure love harvesting Cretaceous flora
                <br />
                DIY farming ‘cause I couldn’t use Quora
                <br />
                On the back of a raptor
                <br />
                I attached a plow
                <br />
                No scallywag raccoon stopping me now
                <br />
                No cock-a-doodle-doo,
                <br />
                Now I wake up to roars
                <br />
                Just travel through time
                <br />
                And this could all be yours
                <br />
                I’m sure my wife is up late missing me
                <br />
                While I’m back in ancient history
                <br />I feel at home in the prehistoric times
                <br />
                Hope you enjoyed these Luh Calm rhymes
                <br />
                <br />
              </>
            ) : (
              <>
                <Typography variant="h3">Say Goodbye</Typography>
                <br />
                Let me tell you about times we’ve shared here
                <br />
                We did it all and we wanted to stay here <br />
                The life was perfect and we knew we had it made
                <br />
                <br />
                Caught you looking while you're shouting my name
                <br />
                Didn’t know we’d get along this kind of way
                <br />
                And now we’re here and we wanted to say
                <br />
                <br />
                They told us we were trouble makers
                <br />
                Through the years we kept getting better
                <br />
                So it’s no longer me and you
                <br />
                <br />I don’t wanna say goodbye
                <br />
                Say goodbye
                <br />
                My heart will break into pieces in front of your eyes
                <br />
                But if we say goodbye
                <br />I will live and you’ll learn I’m the one you deserve and
                you’ll know
                <br />
                If this is ending tonight
                <br />I will let you go
                <br />
                <br />
                We opened up to the world you showed us
                <br />
                Became the band that I we knew we could be
                <br />
                'Cause we knew we were the ones who could open your eyes
                <br />
                <br /> Here today, gone tomorrow
                <br />
                Gave a path for us to follow
                <br />
                Now it's time for us to let you know
                <br />
                <br />
                They told us we were trouble makers
                <br />
                Through the years we kept getting better
                <br />
                So it’s no longer me and you
                <br />
                <br />I don’t wanna say goodbye
                <br />
                Say goodbye
                <br />
                My heart will break into pieces in front of your eyes
                <br />
                But if we say goodbye
                <br />I will live and you’ll learn I’m the one you deserve and
                you’ll know
                <br />
                If this is ending tonight
                <br />I will let you go
                <br />
                <br />
                Now we play a minor 4<br /> Just like the Sea Goats did before
                <br />I think this is ending tonight
                <br />
                <br />I don’t wanna say goodbye
                <br />
                Say goodbye
                <br />
                My heart will break into pieces in front of your eyes
                <br />
                But if we say goodbye
                <br />I will live and you’ll learn I’m the one you deserve and
                you’ll know
                <br />
                if this is ending tonight
                <br />
                (Repeat Chorus)
              </>
            )}
          </Typography>
          {/* </canvas> */}
          {/* <script src="https://cdn.jsdelivr.net/npm/animejs@3.2.1/lib/anime.min.js"></script> */}
          {/* <Typography variant="h4">Lyrics for {song}</Typography> */}
        </Box>
      </Box>
    </Grid>
  );
}
