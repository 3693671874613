import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";

const ErrorPage = () => {
  useEffect(() => {
    document.title = "Error - Phrog Music";
  }, []);

  return (
    <Grid
      container
      direction="column"
      gap={4}
      sx={{
        backgroundImage:
          "linear-gradient(rgba(255, 50, 50, 0.3), rgba(255, 50, 50, 0.7)), url(/images/OhNo.JPG)", // Path relative to the public directory
        backgroundSize: "100% 100vh", // Ensure the image covers the container
        backgroundRepeat: "no-repeat", // Prevent image repetition
        position: "relative", // Position relative for layering
      }}
    >
      <Grid
        direction={"column"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   height: "85vh", // Full viewport height, adjust as needed
          textAlign: "center", // Center text within the Typography component
          marginTop: { xs: 4, md: 15 },
          height: "90vh",
        }}
      >
        <h1 style={styles.header}>404 - Page Not Found</h1>
        <p style={styles.message}>
          The page you are looking for does not exist.
        </p>
        <Link to="/">
          <Button variant={"contained"} color="secondary">
            Go to Home
          </Button>
        </Link>
        {/* <img src="/images/Suprised.png" alt="Phrog Error" style={styles.image} /> */}
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "rgb(255, 10, 10)",
  },
  header: {
    fontSize: "4rem",
    marginBottom: "1rem",
  },
  message: {
    fontSize: "1.5rem",
    marginBottom: "1rem",
  },
  // link: {
  //   fontSize: "1.2rem",
  //   color: "#007bff",
  //   textDecoration: "none",
  // },
  image: {
    // position: "absolute",
    // bottom: 0,
    // width: "100%",
    opacity: "8%",
  },
};

export default ErrorPage;
