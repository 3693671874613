import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

export default function QRCode() {
  useEffect(() => {
    document.title = "QR Code - Phrog Music";
  }, []);
  return (
    <Grid direction="column" container>
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{ marginTop: "90px" }}
      >
        <img width="300px" src="/images/QRCode.png" alt="QR Code" />
      </Box>
    </Grid>
  );
}
